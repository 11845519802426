<template>
	<div
		class="space-between flex flex-col gap-6 md:grid md:auto-rows-fr md:grid-cols-2 md:gap-6 lg:grid-cols-4 xl:grid-cols-7"
	>
		<div
			class="col-span-1 h-full space-y-4 md:col-span-2 lg:col-span-4 xl:col-span-3"
		>
			<h1
				class="mb-0 mt-8 bg-gradient-to-br from-teal-400 to-sky-600 bg-clip-text pb-1 leading-none text-transparent md:mt-0 md:px-20 md:text-center xl:px-0 xl:text-left"
			>
				Drive forward with subscription flexibility and novated savings
			</h1>
			<p class="text-3xl md:text-center xl:text-left">
				Empowering your drive.
				<br />
				Simplified solutions for seamless rides.
			</p>
			<CarbarImage
				image="img/home/img-cars.webp"
				alt-text="carbar cars"
				sizes="460px md:600px"
				image-width-class="w-full m-auto md:w-[600px]"
				image-height-class="h-auto"
				densities="x1 x2"
				loading="eager"
				:preload="true"
			/>
		</div>
		<div class="col-span-2 grid h-full md:col-span-1 lg:col-span-2">
			<CarbarCarCard
				:button-label="`Show me ${carCountSubscription} cars`"
				secondary-button-label="How car subscription works"
				button-href="/cars/subscribe"
				secondary-button-href="/car-subscription"
				terms-href="/terms-and-conditions/car-subscription"
				tick-color="text-orange-500"
				button-background-color="bg-orange-400 hover:bg-orange-600"
				:items="personalItems"
			>
				<template #image>
					<CarbarImage
						image="img/home/img-icon-personal.webp"
						alt-text="Personal"
						sizes="40px md:40px lg:40px"
						densities="x1 x2"
						loading="eager"
						:preload="true"
					/>
				</template>
				<template #title>Personal</template>
				<template #subtitle>
					All-inclusive, flexible car subscription
				</template>
			</CarbarCarCard>
		</div>
		<div class="col-span-2 grid h-full md:col-span-1 lg:col-span-2">
			<CarbarCarCard
				button-label="See our products"
				secondary-button-label="Benefits of a fleet subscription"
				button-href="/business"
				secondary-button-href="/business/fleet-subscription"
				terms-href="/terms-and-conditions/car-subscription"
				tick-color="text-blue-400"
				button-background-color="bg-blue-400 hover:bg-sky-600"
				:items="businessItems"
			>
				<template #image>
					<CarbarImage
						image="img/home/img-icon-business.webp"
						alt-text="Business"
						sizes="380px lg:790px md:600px"
						densities="x1 x2"
						loading="eager"
						:preload="true"
					/>
				</template>
				<template #title>Business</template>
				<template #subtitle>Simplified business solution</template>
			</CarbarCarCard>
		</div>
	</div>
</template>

<script setup>
import CarbarCarCard from '@/components/home/cards/CarCard.vue'
import CarbarImage from '@/components/CarbarImage.vue'
import { useCarbarStore } from '@/stores/carbar'

const carbar = useCarbarStore()

const carCountSubscription = computed(() =>
	carbar.vehicleCount.subscription > 0 ? carbar.vehicleCount.subscription : ''
)

const personalItems = [
	{
		label: 'Exclusive access to a car of your choice',
	},
	{
		label: 'Easy to manage, fixed weekly payments',
	},
	{
		label: 'Stop or swap anytime with 2 weeks notice',
	},
]

const businessItems = [
	{
		label: 'Cost-effective solutions',
	},
	{
		label: 'Flexibility and  optimisation',
	},
	{
		label: 'Maintenance and support',
	},
]
</script>
